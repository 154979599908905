import { useEffect, useState } from 'react';
import Appearance from '@mshops-web-core/appearance';

import { useRenderContext } from '../../pages/home/context';
import ClientService from '../../../services/client';
import { removePreviewParam } from '../../utils/handleReload';
import { COUPONS_INITIAL_SLIDE } from '../../utils/constants/common';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const useApplyCoupon = ({
  initialCoupons,
}) => {
  const { apiBasePath, draftMode, isEditable } = useRenderContext();
  const [couponsState, setCouponsState] = useState(initialCoupons || null);
  const RestClientService = new ClientService(apiBasePath);

  const applyCoupon = async ({
    campaignId,
    code,
  }) => {
    if (isEditable || draftMode) return { success: false, message: 'isEditable' };

    try {
      const response = await RestClientService.post(
        '/smart-coupons',
        {
          data: {
            campaignId,
            code,
          },
        },
        5000,
      );
      const { track_event: trackEvent, ...restData } = response.data;
      const updatedTrackEvent = {
        ...trackEvent,
        melidata_event: {
          ...trackEvent.melidata_event,
          event_data: {
            ...trackEvent.melidata_event.event_data,
            origin: 'followers_my_page',
          },
        },
      };
      const data = { track_event: updatedTrackEvent, ...restData };
      return { success: true, ...data };
    } catch (error) {
      return { success: false, error };
    }
  };

  const getCampaignInfo = async ({
    campaignId,
  } = {}) => {
    try {
      if (isEditable || draftMode) return { success: false, message: 'isEditable' };

      const response = await RestClientService.get(
        '/smart-coupons',
        {
          params: {
            campaignId,
          },
        },
        5000,
      );
      return {
        success: true,
        ...response.data,
      };
    } catch (error) {
      return { success: false, error };
    }
  };

  const updateCoupons = async (props) => {
    try {
      let timeSleep = 800;
      if (props?.data?.callAction === 'stop_follow') {
        timeSleep = 1600;
      }
      await sleep(timeSleep);
      const { data } = await RestClientService.get('/dynamic-layout');

      const appearance = new Appearance(data?.appearance ?? {});
      const { properties } = appearance?.getComponentById?.('coupon-carousel') ?? {};

      setCouponsState(properties?.coupons);
    } catch (error) {
      // TODO: handle error
    }
  };

  useEffect(() => {
    removePreviewParam({
      additionalParams: [COUPONS_INITIAL_SLIDE],
    });

    window.addEventListener('reload-coupons', updateCoupons);

    return () => window.removeEventListener('reload-coupons', updateCoupons);
  }, []);

  return {
    applyCoupon,
    getCampaignInfo,
    couponsState,
    updateCoupons,
  };
};

export default useApplyCoupon;
