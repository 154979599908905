import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import CouponCarousel from '@mshops-components-library/coupon-carousel';

import { useRenderContext } from '../../pages/home/context';

import useCouponModal from './useCouponModal';
import useApplyCoupon from './useApplyCoupon';

const CouponCarouselComponent = (props) => {
  const { isEditable, initialSlideCouponCarousel, commonTrackData } = useRenderContext();
  const { visible, modal, coupons } = props;
  const { applyCoupon, getCampaignInfo, couponsState } = useApplyCoupon({ initialCoupons: coupons });
  const { modalUpdate, modalActions } = useCouponModal({
    isEditable,
    modal,
    commonTrackData,
  });

  if (!visible) {
    return null;
  }

  return (
    <CouponCarousel
      {...props}
      key={uuid()}
      modal={modalUpdate}
      modalActions={modalActions}
      coupons={couponsState}
      applyCoupon={applyCoupon}
      getCampaignInfo={getCampaignInfo}
      initialSlide={initialSlideCouponCarousel ?? 0}
      trackAppliedCoupon={{
        path: '/eshops/coupons/find_products',
        event_data: {
          ...commonTrackData,
          type_coupon: 'not_apply',
        },
      }}
      trackApplyCoupon={{
        path: '/eshops/coupons/activate',
        event_data: {
          ...commonTrackData,
          type_coupon: 'not_apply',
        },
      }}
    />
  );
};

CouponCarouselComponent.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  isEditable: PropTypes.bool,
  modal: PropTypes.shape({}),
  coupons: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CouponCarouselComponent;
